<template>
  <AuthWrap :title="$t('register.signIn')">
    <form @submit.prevent="register">
      <div class="col">
        <div class="col__item input">
          <p class="description primary-text" style="margin-bottom: 0">
            {{ $t("register.addFoto") }}
            <br />
            <span class="btn" v-html="$t('linkHelp')"></span>
          </p>
          <LoadFile
            :isFace="true"
            fileName="Face"
            :size="0.2"
            @getImg="getFace"
            :error="error.face"
            idInput="file1"
            iconPath="M29.7493 7.08333C31.3142 7.08333 32.5827 8.35186 32.5827 9.91667V26.9167C32.5827 28.4815 31.3142 29.75 29.7493 29.75H4.24935C2.68454 29.75 1.41602 28.4815 1.41602 26.9167V7.08333C1.41602 5.51853 2.68454 4.25 4.24935 4.25H12.7493C14.336 4.25 15.346 4.92356 16.3604 6.20338C16.3885 6.2388 16.4569 6.32698 16.5327 6.42463L16.5329 6.42492C16.6398 6.56262 16.7611 6.71903 16.8045 6.77283C16.8485 6.82727 16.8855 6.8723 16.9161 6.90952C17.0407 7.06106 17.0587 7.08303 17.0019 7.08333H29.7493ZM29.7493 26.9167V9.91667L16.9918 9.91665C15.9744 9.9112 15.3117 9.43487 14.5993 8.55177C14.5347 8.47176 14.3779 8.26963 14.2641 8.12285C14.2045 8.04602 14.1566 7.98436 14.1399 7.96331C13.6093 7.29381 13.2937 7.08333 12.7493 7.08333H4.24935V26.9167H29.7493ZM21.2493 18.4167C21.2493 20.7639 19.3466 22.6667 16.9993 22.6667C14.6521 22.6667 12.7493 20.7639 12.7493 18.4167C12.7493 16.0695 14.6521 14.1667 16.9993 14.1667C19.3466 14.1667 21.2493 16.0695 21.2493 18.4167ZM9.91602 18.4167C9.91602 22.3287 13.0873 25.5 16.9993 25.5C20.9114 25.5 24.0827 22.3287 24.0827 18.4167C24.0827 14.5046 20.9114 11.3333 16.9993 11.3333C13.0873 11.3333 9.91602 14.5046 9.91602 18.4167ZM25.4993 12.75C25.4993 13.5324 26.1336 14.1667 26.916 14.1667C27.6984 14.1667 28.3327 13.5324 28.3327 12.75C28.3327 11.9676 27.6984 11.3333 26.916 11.3333C26.1336 11.3333 25.4993 11.9676 25.4993 12.75Z"
          />
        </div>

        <div class="col__item input">
          <p
            v-if="typeDoc == 'passport' || isKorean || (typeDoc == 'metrika' || !isKorean)"
            class="description"
          >
            {{ $t("register.addPassport") }}
          </p>
          <p v-if="typeDoc == 'international'" class="description">
            {{ $t("NewRegister.internationalCard") }}
          </p>
          <p v-if="typeDoc == 'id_doc'" class="description">
            {{ $t("NewRegister.idCard") }}
          </p>
          <LoadFile
            :size="2"
            @getImg="getImg"
            :error="error.document"
            :text="$t('register.scanBtn')"
            idInput="file2"
            iconPath="M29.7493 7.08333C31.3142 7.08333 32.5827 8.35186 32.5827 9.91667V26.9167C32.5827 28.4815 31.3142 29.75 29.7493 29.75H4.24935C2.68454 29.75 1.41602 28.4815 1.41602 26.9167V7.08333C1.41602 5.51853 2.68454 4.25 4.24935 4.25H12.7493C14.336 4.25 15.346 4.92356 16.3604 6.20338C16.3885 6.2388 16.4569 6.32698 16.5327 6.42463L16.5329 6.42492C16.6398 6.56262 16.7611 6.71903 16.8045 6.77283C16.8485 6.82727 16.8855 6.8723 16.9161 6.90952C17.0407 7.06106 17.0587 7.08303 17.0019 7.08333H29.7493ZM29.7493 26.9167V9.91667L16.9918 9.91665C15.9744 9.9112 15.3117 9.43487 14.5993 8.55177C14.5347 8.47176 14.3779 8.26963 14.2641 8.12285C14.2045 8.04602 14.1566 7.98436 14.1399 7.96331C13.6093 7.29381 13.2937 7.08333 12.7493 7.08333H4.24935V26.9167H29.7493ZM21.2493 18.4167C21.2493 20.7639 19.3466 22.6667 16.9993 22.6667C14.6521 22.6667 12.7493 20.7639 12.7493 18.4167C12.7493 16.0695 14.6521 14.1667 16.9993 14.1667C19.3466 14.1667 21.2493 16.0695 21.2493 18.4167ZM9.91602 18.4167C9.91602 22.3287 13.0873 25.5 16.9993 25.5C20.9114 25.5 24.0827 22.3287 24.0827 18.4167C24.0827 14.5046 20.9114 11.3333 16.9993 11.3333C13.0873 11.3333 9.91602 14.5046 9.91602 18.4167ZM25.4993 12.75C25.4993 13.5324 26.1336 14.1667 26.916 14.1667C27.6984 14.1667 28.3327 13.5324 28.3327 12.75C28.3327 11.9676 27.6984 11.3333 26.916 11.3333C26.1336 11.3333 25.4993 11.9676 25.4993 12.75Z"
          />
        </div>
        <div v-if="(isKorean && typeDoc == 'metrika') || isKorean" class="col__item input">
          <p class="description">
            {{ $t("register.addMetrik") }}
          </p>
          <LoadFile
            @getImg="getMetrika"
            :size="2"
            :error="error.metrika"
            :text="$t('register.scanBtn')"
            idInput="file3"
            iconPath="M29.7493 7.08333C31.3142 7.08333 32.5827 8.35186 32.5827 9.91667V26.9167C32.5827 28.4815 31.3142 29.75 29.7493 29.75H4.24935C2.68454 29.75 1.41602 28.4815 1.41602 26.9167V7.08333C1.41602 5.51853 2.68454 4.25 4.24935 4.25H12.7493C14.336 4.25 15.346 4.92356 16.3604 6.20338C16.3885 6.2388 16.4569 6.32698 16.5327 6.42463L16.5329 6.42492C16.6398 6.56262 16.7611 6.71903 16.8045 6.77283C16.8485 6.82727 16.8855 6.8723 16.9161 6.90952C17.0407 7.06106 17.0587 7.08303 17.0019 7.08333H29.7493ZM29.7493 26.9167V9.91667L16.9918 9.91665C15.9744 9.9112 15.3117 9.43487 14.5993 8.55177C14.5347 8.47176 14.3779 8.26963 14.2641 8.12285C14.2045 8.04602 14.1566 7.98436 14.1399 7.96331C13.6093 7.29381 13.2937 7.08333 12.7493 7.08333H4.24935V26.9167H29.7493ZM21.2493 18.4167C21.2493 20.7639 19.3466 22.6667 16.9993 22.6667C14.6521 22.6667 12.7493 20.7639 12.7493 18.4167C12.7493 16.0695 14.6521 14.1667 16.9993 14.1667C19.3466 14.1667 21.2493 16.0695 21.2493 18.4167ZM9.91602 18.4167C9.91602 22.3287 13.0873 25.5 16.9993 25.5C20.9114 25.5 24.0827 22.3287 24.0827 18.4167C24.0827 14.5046 20.9114 11.3333 16.9993 11.3333C13.0873 11.3333 9.91602 14.5046 9.91602 18.4167ZM25.4993 12.75C25.4993 13.5324 26.1336 14.1667 26.916 14.1667C27.6984 14.1667 28.3327 13.5324 28.3327 12.75C28.3327 11.9676 27.6984 11.3333 26.916 11.3333C26.1336 11.3333 25.4993 11.9676 25.4993 12.75Z"
          />
        </div>
      </div>
      <div class="captcha">
        <vue-recaptcha
          ref="captcha"
          @error="onError"
          @verify="verifyMethod"
          @expired="expiredMethod"
          sitekey="6LeAeecjAAAAAKQkDSRo6j1fOcHGRYtNAwhZMFGD"
        />
      </div>
      <div v-if="error.verifyError" class="no-valid mb">
        {{ $t("verify_c_error") }}
      </div>
      <PrimaryButton :title="$t('buttons.register')" />
    </form>
  </AuthWrap>
</template>

<script>
import { useStore } from "vuex";
import { computed, reactive, ref } from "vue";
import LoadFile from "./LoadFile.vue";
import { signUp } from "@/network/v1/index";
import { useRouter } from "vue-router";
import { useLanguage } from "@/utils/language";
import AuthWrap from "../AuthWrap.vue";
import PrimaryButton from "../PrimaryButton.vue";
import { VueRecaptcha } from "vue-recaptcha";

export default {
  components: { LoadFile, AuthWrap, PrimaryButton, VueRecaptcha },
  setup() {
    const router = useRouter();
    const store = useStore();
    const { i18nRoute } = useLanguage();
    const user = computed(() => store.getters["register/getUser"]);
    // const sixtyYearOld = 504921600000;
    const seventeen = 536468184000;

    const isPassport = computed(() => {
      return seventeen < new Date() - new Date(user.value.birth_date);
    });

    const captcha = ref(null);
    const cVerify = ref(false);

    const info = reactive({
      document: null,
      face: null,
      metrika: null,
      recaptcha: null
    });

    const error = reactive({
      document: false,
      face: false,
      metrika: false,
      verifyError: false,
    });

    const success = reactive({
      document: false,
      face: false,
      metrika: false,
    });

    const verifyMethod = (response) => {
      if (response) {
        info.recaptcha = response;
        error.verifyError = false;
        cVerify.value = true;
      } else {
        error.verifyError = true;
        cVerify.value = false;
      }
    };

    const expiredMethod = () => {
      cVerify.value = false;
      captcha.value.reset();
    };

    const onError = () => {
      error.verifyError = true;
    };

    const getFace = (data) => {
      info.face = data;
    };
    const getImg = (data) => {
      info.document = data;
      console.log(data);
    };
    const getMetrika = (data) => {
      info.metrika = data;
    };

    const register = () => {
      setTimeout(() => {
        error.face = false;
        error.metrika = false;
        error.document = false;
      }, 2000);
      if (cVerify.value) {
        error.recaptcha = false;

        if (user.value.is_korean) {
          // if (seventeen < new Date() - new Date(user.value.birth_date)) {
            for (const el in info) {
              if (info[el]) {
                error[el] = false;
              } else {
                error[el] = true;
              }
            }
            store.commit("register/setUser", {
              document: info.document,
              face: info.face,
              metrika: info.metrika,
              recaptcha: info.recaptcha,
            });
          // } else {
          //   for (const el in info) {
          //     if (el == "metrika" || el == "face") {
          //       if (info[el]) {
          //         error[el] = false;
          //       } else {
          //         error[el] = true;
          //       }
          //     }
          //   }
          //   store.commit("register/setUser", {
          //     document: info.metrika,
          //     face: info.face,
          //     recaptcha: info.recaptcha,
          //     cb: () => {},
          //   });
          // }
        } else {
          // if (seventeen > new Date() - new Date(user.value.birth_date)) {
          //   for (const el in info) {
          //     if (el == "metrika" || el == "face") {
          //       if (info[el]) {
          //         error[el] = false;
          //       } else {
          //         error[el] = true;
          //       }
          //     }
          //   }
          //   store.commit("register/setUser", {
          //     document: info.metrika,
          //     face: info.face,
          //     recaptcha: info.recaptcha,
          //     // metrika: info.metrika,
          //   });
          // } else {
            for (const el in info) {
              if (el == "document" || el == "face") {
                if (info[el]) {
                  error[el] = false;
                } else {
                  error[el] = true;
                }
              }
            }
            store.commit("register/setUser", {
              document: info.document,
              face: info.face,
              recaptcha: info.recaptcha,
              cb: () => {},
            });
          // }
        }
      } else {
        error.recaptcha = true;
      }

      const formData = new FormData();
      for (const name in user.value) {
        formData.append(name, user.value[name]);
      }

      signUp(
        (res) => {
          if (res) {
            router.push(
              i18nRoute({
                name: "Auth",
                query: { registered: "true" },
              })
            );
          }
        },
        formData,
        () => {
          setTimeout(() => {
            error.face = false;
            error.metrika = false;
            error.document = false;
          }, 2000);
        }
      );
    };

    return {
      choicesLabel: computed(() => store.getters["register/getchoicesLabel"]),
      isKorean: computed(() => store.getters["register/getUser"].is_korean),
      user: computed(() => store.getters["register/getUser"]),
      getFace,
      getImg,
      getMetrika,
      register,
      onError,
      verifyMethod,
      expiredMethod,
      error,
      info,
      VueRecaptcha,
      success,
      cVerify,
      captcha,
      isPassport,
      typeDoc: computed(() => store.getters["register/getDocType"]),
    };
  },
};
</script>

<style lang="scss" scoped>
.btn {
  font-weight: 500;
  display: block;
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  cursor: default;
  color: var(--dark-blue);
  font-size: 1rem;

  :deep() a {
    color: var(--primary-blue);
    border-bottom: 1px solid var(--primary-blue) !important;
  }
}
.captcha {
  margin-bottom: 1rem;
}
.mb {
  margin-bottom: 1rem;
}
.gray {
  color: var(--gray-font-color);
  font-weight: 500;
}
label {
  margin-bottom: 1rem;
  font-weight: 500;
}
.send-btn {
  margin-top: 2em;
  margin-bottom: 2em;
  padding: 0.8em 1.5em;
  font-size: 1.2em;
}
.col {
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
  &__item {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5rem;
    :first-child {
      margin-bottom: 1.5rem;
    }
    .description {
      font-size: 1rem;
      margin-bottom: 1.875rem;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
@media screen and (max-width: 1200px) {
  .col {
    height: auto;
  }
}

@media screen and (max-width: 576px) {
  .col {
    &__item {
      max-width: 100%;

      .description {
        font-size: 0.75rem;
        margin-bottom: 1.25rem;
      }
    }
  }
}
</style>
